.startRowBottomSpacing {
    padding-bottom: 24px;
}

.longForm {
    width: 100%;
}

.shortForm {
    width: 128px;
}

.shortFormRightSpacing {
    display: inline-block;
    padding-right: 16px;
}

.header-wrap {
    height: 56px;
}

.header {
    position: relative;
}

.header h6 {
    line-height: 1rem;
    height: 16px;
    padding-top: 12px;
}

.header .MuiTypography-caption {
    line-height: 1rem;
    height: 16px;
    padding-top: 6px;
    display: block;
}

.header .MuiIconButton-root {
    position: absolute;
    top: 0;
    right: 0;
    padding: 16px;
}