@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@500&family=IBM+Plex+Sans:wght@400;500&display=swap');

hmtl {
  font-size: 16px;
}

body {
  font-family: 'IBM Plex Sans', sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.87);
  display: block;
  letter-spacing: 0.025rem;
}

code {
  font-family: 'IBM Plex Mono', monospace;
}

button {
  letter-spacing: 0.06rem;
}

.MuiTypography-caption {
  letter-spacing: 0.03rem;
}

.MuiFormLabel-root {
  letter-spacing: 0.03rem;
}

