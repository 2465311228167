@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@500&family=IBM+Plex+Sans:wght@400;500&display=swap);
hmtl {
  font-size: 16px;
}

body {
  font-family: 'IBM Plex Sans', sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.87);
  display: block;
  letter-spacing: 0.025rem;
}

code {
  font-family: 'IBM Plex Mono', monospace;
}

button {
  letter-spacing: 0.06rem;
}

.MuiTypography-caption {
  letter-spacing: 0.03rem;
}

.MuiFormLabel-root {
  letter-spacing: 0.03rem;
}


.App {
  /* text-align: center; */
}
#defaultCanvas0 {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -5;
}
.controlPanel {
    position: fixed;
    padding: 2rem;
    background-color: white;
    width: 272px;
    border-radius: 1.5rem;
    bottom: 4rem;
    left: 50%;
    transform: translate(-50%, 0);
  }

.controlRow {
  height: 36px;
}

.controlRow button {
  height: 36px;
  float: right;
}

.controlRowBottomSpacing {
  padding-bottom: 16px;
}
.startRowBottomSpacing {
    padding-bottom: 24px;
}

.longForm {
    width: 100%;
}

.shortForm {
    width: 128px;
}

.shortFormRightSpacing {
    display: inline-block;
    padding-right: 16px;
}

.header-wrap {
    height: 56px;
}

.header {
    position: relative;
}

.header h6 {
    line-height: 1rem;
    height: 16px;
    padding-top: 12px;
}

.header .MuiTypography-caption {
    line-height: 1rem;
    height: 16px;
    padding-top: 6px;
    display: block;
}

.header .MuiIconButton-root {
    position: absolute;
    top: 0;
    right: 0;
    padding: 16px;
}
.minimal {
    position: fixed;
    top: 24px;
    right: 24px;
}

.minimal .MuiIconButton-root {
    padding: 16px;
    background-color: rgba(255, 255, 255, 0.33);
}

.minimal .MuiIconButton-root:hover {
    padding: 16px;
    background-color: rgba(255, 255, 255, 0.87);
}
.time {
    font-size: 1.25rem;
    line-height: 36px;
    float: left;
    vertical-align: middle;
    font-family: 'IBM Plex Mono', monospace;
    letter-spacing: 0.02rem;
}

.blink {
    animation:3s blinker linear infinite;
    -webkit-animation:3s blinker linear infinite;
    -moz-animation:3s blinker linear infinite;
  }
  
  @-webkit-keyframes blinker {  
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
  }
  
  @keyframes blinker {  
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
  }
.MuiFormControlLabel-root {
    margin-left: 0!important;
    margin-right: 0!important;
    height: 56px;
}

.MuiSwitch-root {
    position: absolute!important;
    right: 0;
}

.MuiFormGroup-root {
    margin-bottom: 24px;
}
