.controlPanel {
    position: fixed;
    padding: 2rem;
    background-color: white;
    width: 272px;
    border-radius: 1.5rem;
    bottom: 4rem;
    left: 50%;
    transform: translate(-50%, 0);
  }

.controlRow {
  height: 36px;
}

.controlRow button {
  height: 36px;
  float: right;
}

.controlRowBottomSpacing {
  padding-bottom: 16px;
}