.minimal {
    position: fixed;
    top: 24px;
    right: 24px;
}

.minimal .MuiIconButton-root {
    padding: 16px;
    background-color: rgba(255, 255, 255, 0.33);
}

.minimal .MuiIconButton-root:hover {
    padding: 16px;
    background-color: rgba(255, 255, 255, 0.87);
}