.MuiFormControlLabel-root {
    margin-left: 0!important;
    margin-right: 0!important;
    height: 56px;
}

.MuiSwitch-root {
    position: absolute!important;
    right: 0;
}

.MuiFormGroup-root {
    margin-bottom: 24px;
}