.time {
    font-size: 1.25rem;
    line-height: 36px;
    float: left;
    vertical-align: middle;
    font-family: 'IBM Plex Mono', monospace;
    letter-spacing: 0.02rem;
}

.blink {
    animation:3s blinker linear infinite;
    -webkit-animation:3s blinker linear infinite;
    -moz-animation:3s blinker linear infinite;
  }
  
  @-moz-keyframes blinker {  
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
  }
  
  @-webkit-keyframes blinker {  
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
  }
  
  @keyframes blinker {  
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
  }